import clsx from 'clsx';
import { forwardRef, RefObject } from 'react';
import { HeaderGroup, Row, TableBodyPropGetter, TableBodyProps, TablePropGetter, TableProps } from 'react-table';
import { VirtualItem } from 'react-virtual';

import LeftChevron from '@ping/assets/Icon/left-chevron.svg';
import NoDataIcon from '@ping/assets/Icon/no-data-found.svg';
import cssVariable from '@ping/assets/scss/theme/exportVariables.module.scss';
import { Spinner } from '@ping/uikit';
import { nop } from '@ping/utils';

import style from './style.module.scss';

interface ITableElement {
  data: any[];
  rows: Row<any>[] | VirtualItem[];
  tableRows?: Row<any>[];
  isLoading: boolean;
  prepareRow: (row: Row<any>) => void;
  headerGroups: HeaderGroup<any>[];
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
  getTableProps: (propGetter?: TablePropGetter<any>) => TableProps;
  isStickyHeader?: boolean;
  isStickyColumn?: boolean;
  isFetchingNextPage?: boolean;
  hasInfiniteLoading?: boolean;
  hasVirtualList?: boolean;
  hasScrollRef?: boolean;
  emptyView?: React.ReactNode;
  disableAllColumnsSorting?: boolean;
  enableStickyForMobile?: boolean;
  getRowProps?: (row) => typeof row;
  getHeaderProps?: (column) => typeof column;
  className?: string;
  VirtualListPaddingTop?: JSX.Element;
  VirtualListPaddingBottom?: JSX.Element;
}
const columnTextAlignment = {
  center: style['column-text-center'],
  start: style['column-text-start'],
  end: style['column-text-end'],
};

const TableElement = forwardRef(
  (
    {
      data = [],
      rows,
      tableRows = [],
      isLoading,
      prepareRow,
      headerGroups,
      getHeaderProps = nop,
      getRowProps = nop,
      getTableBodyProps,
      getTableProps,
      emptyView,
      isStickyHeader = true,
      isStickyColumn = false,
      isFetchingNextPage = false,
      hasInfiniteLoading = false,
      hasVirtualList = false,
      hasScrollRef = false,
      disableAllColumnsSorting = false,
      enableStickyForMobile,
      className = '',
      VirtualListPaddingTop,
      VirtualListPaddingBottom,
    }: ITableElement,
    ref: RefObject<HTMLDivElement>
  ) => {
    const hasData = data?.length !== 0;

    /*
      Render the UI for your table
      - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
    */
    return (
      <>
        <table
          {...getTableProps()}
          className={clsx(
            {
              [style['header']]: isStickyHeader,
              [style['sticky']]: hasData && (isStickyColumn || enableStickyForMobile),
            },
            style['table'],
            className
          )}
        >
          <thead
            style={{
              top:
                hasVirtualList || enableStickyForMobile || isStickyHeader || isStickyColumn
                  ? 0
                  : cssVariable.HeaderHeight,
            }}
          >
            {headerGroups.map((headerGroup, index) => {
              const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
              return (
                <tr key={headerGroupKey} {...headerGroupProps} data-index={index % 2 ? 'odd' : 'even'}>
                  {headerGroup.headers.map(column => {
                    const { key: headerKey, ...headerProps } = column.getHeaderProps(
                      !disableAllColumnsSorting && hasData && !column.disableColumnSorting
                        ? getHeaderProps(column)?.title
                          ? getHeaderProps(column)
                          : column.getSortByToggleProps()
                        : undefined
                    );
                    return (
                      <th
                        key={headerKey}
                        className={clsx(
                          columnTextAlignment[column.columnTextAlignment],
                          hasData && !column.disableColumnSorting && style['headers-hover']
                        )}
                        // Now each column has access to its width/minWidth/maxWidth and can directly control its width inside themselves
                        {...column.getHeaderProps({
                          style: { width: column.width, minWidth: column.minWidth, maxWidth: column.maxWidth },
                        })}
                        {...headerProps}
                      >
                        <span className={style['table-text']}>
                          {column.render('Header') as string}
                          {/* Add a sort direction indicator */}
                          {hasData && column.isSorted && !column.disableColumnSorting && (
                            <LeftChevron
                              className={style['sort-icon']}
                              data-sorted-desc={Boolean(column.isSortedDesc)}
                            />
                          )}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {hasVirtualList && VirtualListPaddingTop}
            {rows.map((rowItem, index) => {
              const row = hasVirtualList ? tableRows[rowItem.index] : rowItem;
              prepareRow(row);

              const { key: rowKey, ...rowProps } = row.getRowProps(getRowProps(row));
              return (
                <tr
                  key={rowKey}
                  data-index={(hasVirtualList ? rowItem.index : index) % 2 ? 'odd' : 'even'}
                  {...rowProps}
                >
                  {row.cells.map(cell => {
                    const { key: cellKey, ...cellProps } = cell.getCellProps();
                    return (
                      <td
                        key={cellKey}
                        className={columnTextAlignment[cell.column.columnTextAlignment]}
                        // Now each column has access to its width/minWidth/maxWidth and can directly control its width inside themselves
                        {...cell.getCellProps({
                          style: {
                            width: cell.column.width,
                            minWidth: cell.column.minWidth,
                            maxWidth: cell.column.maxWidth,
                          },
                        })}
                        {...cellProps}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {(hasInfiniteLoading || hasScrollRef) && (
              <tr className={style['observer-element']}>
                <td>
                  <div ref={ref} />
                </td>
              </tr>
            )}
            {hasVirtualList && VirtualListPaddingBottom}
          </tbody>
        </table>
        {!isLoading && !hasData && (emptyView || <NoDataIcon className={style['table__no-data-icon']} />)}
        {hasInfiniteLoading && (
          <div className={style['spinner']}>
            <Spinner isLoading={isFetchingNextPage} size={8} />
          </div>
        )}
      </>
    );
  }
);

export default TableElement;
